import { useCallback, useEffect, useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
// import { fetchMyProfile } from "../redux/actions/ProfileActions";
import { setAuthToken, setIsTrialExpired, setUser, setUserIsSupervisor, useIsSupervisor } from "../redux/reducers/AuthReducer"
import { fetchProfile } from "../redux/sagas/profile/fetchSaga";

import ApiService from "../services/ApiService"
import LocalStorage from "../services/LocalStorage"
import { AUTH_TOKEN, USER_DATA } from "./CONSTANTS"
import moment from 'moment';

export function useToken() {
  const dispatch = useDispatch()
  const token = LocalStorage.getData(AUTH_TOKEN)
  const user = LocalStorage.getData(USER_DATA);
  if (token) {
    ApiService.setAuthHeader(token)
    dispatch(setAuthToken(token))
    dispatch(setUser(user))
    dispatch(fetchProfile())
  }
  return token
}

export function useSupervisor() {
  const dispatch = useDispatch();
  let isSupervisor = useIsSupervisor(); 
  if(isSupervisor===null) {
    const user = LocalStorage.getData(USER_DATA);
    if(user){
      isSupervisor = user.is_supervisor;
      dispatch(setUserIsSupervisor(user.is_supervisor));
    }
  }
  return isSupervisor
}

export const useDispatchEffect = (sagaAction, params=null, condition=true) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (condition) dispatch(sagaAction(params))
  }, [condition])

  const onRefresh = () => {
    dispatch(sagaAction(params))
  }
  return onRefresh
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function calculateWeeks(date=new Date()) {
    const weeks = []; let month = date.getMonth();
    date.setDate(1); 
    const monthStart = moment(date); 
    const weekStart = moment(date).isoWeekday(1);
    const weekOfYear = weekStart.isoWeek(); 
    const isMonthSame = weekStart.month() === month; 
    let startDate = isMonthSame ? weekStart.isoWeek(weekOfYear) : weekStart.isoWeek(weekOfYear+1);
    for (let i = 0; i < 4; i++) {
      weeks.push({ from: startDate.format('YYYY-MM-DD'), to: moment(startDate).add(6,'d').format('YYYY-MM-DD') });
      startDate.add(7,'days');
      month = startDate.month();     
    }
    return weeks;
}
