export const scrollToTop = () => { 
    try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
 }
 export const findSum = (key, data=[]) => { 
    let sum=0;
    data.forEach(item => {
      sum += item[key] || 0;
    });  
    return sum;
  }

  export const findPercentage = (num=0, total=1) => { 
    if(total > 0)
      return Number(num/total*100);
    else return 0;
   }