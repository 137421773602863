import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { PRIVACY, TERMS } from '../utils/CONSTANTS';
import ApiService from '../services/ApiService';
import '../screens/privacyPolicy/Privacy.css';
import '../screens/termAndcondition/termsAndCondition.css';
import LoadingSpinner from './LoadingSpinner';
import { Stack } from '@mui/material';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, handleClose, title }) {

    const [content, setContent] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const fetchContent = async (title) => { 
      setLoading(true);
      try {
        let res;
        if(title === TERMS)
          res= await ApiService.getTermsConditions();
        else if(title === PRIVACY)
          res= await ApiService.getPrivacyPolicy();
        if(!res) return;
        console.log('res.data: ', res.data)
        const results = res?.data?.results;
        if(results) 
          setContent(results[0]?.body);  
        else 
          setContent('Not found')
      } catch (error) {
        console.log({ error });
      } finally {
        setLoading(false);
      }
     }
  
    React.useEffect(() => {
      fetchContent(title)
    }, []);
  
  return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: '#D37498' }}>
          <Toolbar sx={{ background: '#D37498' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>

        {
            loading ? 
            <LoadingSpinner style={{ height: '100vh', width: '100%'}}/> 
            :
            <Stack width='100%' p={5}>
            {htmlToReactParser.parse(content)}
            </Stack>
        }


      </Dialog>
  );
}
