import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useBadgeValue,
  useUserProfile,
} from "../../redux/reducers/ProfileReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../redux/reducers/SidebarReducer";
import { signOutAction } from "../../redux/sagas/auth/AuthSagas";
import badge from "../../assets/badge.png";
import "./ProfileScreen.css";
import { useDispatchEffect } from "../../utils/hooks";
import { fetchBadges } from "../../redux/sagas/profile/fetchBadgesSaga";
import swal from "sweetalert";

const ProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSidebar = useShowSidebar();
  const user = useUserProfile();
  const badgeValue = useBadgeValue();

  const confirmLogout = () => {
    swal({
      title: "Are you sure you want to logout?",
      // text: "you want to Logout!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onLogout();
        // swal("You are Logged out Succeccfully!", {
        //   icon: "success",
        // });
      }
    });
  };

  const onLogout = () => {
    dispatch(signOutAction());
    navigate("/");
    window.location.reload();
  };

  useDispatchEffect(fetchBadges, null, true);

  return (
    <div className="section has-light-background">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <p className="headingMain">Profile</p>

          <p className="pera mt60">Name</p>
          <p className="heading3rd ">{user.name}</p>
          <p className="pera mt30">Email</p>
          <p className="heading3rd ">{user.email}</p>

          <p className="pera mt40">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/password-change-profile`)}
            >
              Change Password
            </a>
          </p>

          <p className="pera mt20">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/terms-condition`)}
            >
              Terms and Conditions
            </a>
          </p>
          <p className="pera mt20">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/privacy`)}
            >
              Privacy Policy
            </a>
          </p>

          <p className="mt40">
            <a className=" changelink mt60" onClick={confirmLogout}>
              Log out
            </a>
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-two-thirds mainBadges ">
          <p> Badges</p>
          <div className="badges mt50">
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 10 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 10 ? "dark" : "light"}>
                10% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 20 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 20 ? "dark" : "light"}>
                20% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 30 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 30 ? "dark" : "light"}>
                30% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 40 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 40 ? "dark" : "light"}>
                40% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 50 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 50 ? "dark" : "light"}>
                50% Completed
              </p>
            </div>
          </div>
          <div className="badges mt50">
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 60 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 60 ? "dark" : "light"}>
                60% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 70 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 70 ? "dark" : "light"}>
                70% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 80 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 80 ? "dark" : "light"}>
                80% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 90 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 90 ? "dark" : "light"}>
                90% Completed
              </p>
            </div>
            <div className="nestedDivBadges">
              <img
                className={badgeValue >= 100 ? "dark" : "light"}
                src={badge}
              />
              <p className={badgeValue >= 100 ? "dark" : "light"}>
                100% Completed
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
