import React from "react";
import { Chart, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(Tooltip, Title, ArcElement, Legend);

export default function DoughnutChart(props) {
  const { label, data, backgroundColor, hoverOffset, width, height } = props;
  const total = data.reduce((r, c) => r + c, 0);
  // console.log('data: ', data, total)

  return (
    <div style={{ position: "relative" }}>
      <Doughnut
        {...props}
        style={{ width, height }}
        data={{
          datasets: [
            {
              label,
              data,
              backgroundColor,
              hoverOffset,
              spacing: 0,
            },
          ],
        }}
      />
      {/* <div className="percent-value">{(data[0] * 100) / data[0]}%</div> */}
      <div className="percent-value">
        {parseFloat((data[0] / total) * 100).toFixed(1)}%
      </div>
    </div>
  );
}
