import "./subcription.css";
import { useDispatchEffect } from "../../utils/hooks";
import { fetchSubscriptions } from "../../redux/sagas/subscription/fetchSaga";
import {
  useData,
  useIsLoading,
} from "../../redux/reducers/SubscriptionReducer";
import LoadingSpinner from "../../components/LoadingSpinner";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useIsTrialExpired } from "../../redux/reducers/AuthReducer";

const Subcription = () => {
  const navigate = useNavigate();
  const isTrialExpired = useIsTrialExpired();
  let subscriptions = useData();
  const isLoading = useIsLoading();
  // const trial = subscriptions.filter((e) => e.name == "Trial");
  subscriptions = subscriptions.filter((e) => e.name != "Trial");
  useDispatchEffect(fetchSubscriptions, null, subscriptions?.length == 0);

  const [selected, setSelected] = useState(null);

  return (
    <section className="section has-left-background">
      <div className="columns is-centered">
        <div className="column"></div>
        <div className="column">
          <div className="columns is-mobile is-centered">
            <div className="column is-four-fifths">
              <p className="Subscription-text">Choose Subscription</p>
            </div>
          </div>

          <div className="columns is-mobile is-centered mt60">
            <button
              className="btn-trial"
              onClick={() => navigate("/student/logHour")}
              disabled={isTrialExpired}
            >
              Start the 7 day free trial
            </button>
          </div>

          {/* <div className="row subsCard" style={{ flexWrap: "nowrap" }}>
            {isLoading ? (
              <LoadingSpinner style={{ height: "230px", width: "50%" }} />
            ) : (
              !isTrialExpired &&
              trial.map((item) => {
                return (
                  <div
                    className="col-md-4 col-lg-4 col-sm-4 mr-5"
                    key={item.id}
                  >
                    <label>
                      <input
                        type="radio"
                        name="subscription"
                        className="card-input-element"
                        onClick={(_) => {
                          setSelected(item.id);
                        }}
                      />

                      <div className="panel panel-default card-input">
                        <div className="btn-trial">
                          <p
                            className="subtitle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            Start the 7 day free trial
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })
            )}
          </div> */}
          <div className="row subsCard" style={{ flexWrap: "nowrap" }}>
            {isLoading ? (
              <LoadingSpinner style={{ height: "230px", width: "50%" }} />
            ) : (
              subscriptions.map((item) => {
                return (
                  <div
                    className="col-md-4 col-lg-4 col-sm-4 mr-5"
                    key={item.id}
                  >
                    <label>
                      <input
                        type="radio"
                        name="subscription"
                        className="card-input-element"
                        onClick={(_) => {
                          setSelected(item.id);
                        }}
                      />

                      <div className="panel panel-default card-input">
                        <div className="panel-head">
                          <p className="subtitle">{item.name}</p>
                          <h1
                            className="title"
                            style={{ marginTop: 40, marginBottom: 25 }}
                          >
                            <sub style={{ fontSize: 25 }}>$</sub>{" "}
                            {item.price.toFixed(2)}
                          </h1>
                          <p className="subtitle2">
                            {item.period === "Month"
                              ? `$${(item.price * 12).toFixed(2)}/Year`
                              : `$${(item.price / 12).toFixed(2)}/Month`}
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })
            )}
          </div>

          <div className="columns is-centered mt120">
            <div className="column is-half bottom-text-div">
              <a
                className="button is-large continue-button is-rounded signin-button size_btn"
                onClick={(_) => navigate("/payment?subscription=" + selected)}
                style={
                  selected
                    ? { background: "#d37498" }
                    : {
                        pointerEvents: "none",
                        cursor: "not-allowed",
                        background: "grey",
                        color: "black",
                      }
                }
              >
                Continue
              </a>
            </div>
          </div>

          <div className="columns is-centered ">
            <div className="column is-half bottom-text-div">
              <button
                style={{ textAlign: "center" }}
                className="cancel-text SubCancelText"
                onClick={(_) => navigate("/")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subcription;
