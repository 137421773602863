import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setUserProfile, setError } from "../../reducers/ProfileReducer";

export const fetchProfile = createAction("profile/fetchProfile");

function* fetchData() {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getProfile);
    console.log('fetchProfile res.data: ', res.data);
    if(res.data.results)
        yield put(setUserProfile(res.data.results[0]));
    else if(res.data.detail) {
      yield put(setError(res.data.detail))
      toast.error(res.data.detail)
    }
  } catch (error) {
    console.log({ error });

  }
}

export function* fetchProfileSaga() {
  yield takeLatest(fetchProfile, fetchData);
}
