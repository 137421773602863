import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import MultipleSelect from "../../../components/MultipleSelect";
import Tooltip from "../../../components/Tooltip";
import {
  setSuccess,
  useErrors,
  useFormData,
  useIsLoading,
  useMonthlyChecks,
  useSuccess,
} from "../../../redux/reducers/ExpLogReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import { fetchMonthlyCheck } from "../../../redux/sagas/experienceLog/fetchMonthlyCheckSaga";
import { fetchStaticData } from "../../../redux/sagas/experienceLog/formDataSaga";
import { submitExpLog } from "../../../redux/sagas/experienceLog/submitFormSaga";
import {
  GROUP_HELP,
  INDEPENDENT_HELP,
  OBSERVATION_HELP,
  RESTRICTED_HELP,
  UNRESTRICTED_HELP,
} from "../../../utils/CONSTANTS";
import { useDispatchEffect } from "../../../utils/hooks";
import { validateLogHourForm } from "../../../utils/validation";
import "./logHour.css";

const LogHour = () => {
  const data = useFormData();
  const isLoading = useIsLoading();
  let {
    expTypes,
    setting,
    supervisors,
    obsevations,
    methods,
    contacts,
    tasks,
  } = data;
  // tasks.length && tasks.sort((a,b) => (a.id - b.id));

  const taskNames = tasks?.map((t) => t.name.split(" ")[0]);
  const taskIds = tasks?.map((t) => t.id);

  useDispatchEffect(fetchStaticData, null, expTypes?.length == 0);

  const initialValues = {
    expereince_type: 0,
    setting: 0,
    method_of_supervision: "",
    client_observation: "",
    supervision_contact: 0,
    task: [],
    supervisor: 0,
    date_of_experience: moment().format("MM/DD/YYYY"),
    // time_of_experience: "",
    time_of_expereince_start: "",
    time_of_expereince_end: "",
    unrestricted_hours: "",
    restricted_hours: "",
    experience_hours: "",
    individual_or_group: "",
    supervision_start_time: "",
    supervision_end_time: "",
    supervised_hours: "",
    independant_hours: "",
    experience_note: "",
  };

  const dispatch = useDispatch();

  const onSubmit = (values, onSubmitProps) => {
    const selected = values.task;
    const newValues = {
      ...values,
      task: selected.map((t) => taskIds[taskNames.indexOf(t)]),
    };
    if (values.individual_or_group == "No supervision") {
      newValues["method_of_supervision"] = null;
      newValues["supervision_start_time"] = null;
      newValues["supervision_end_time"] = null;
      newValues["supervised_hours"] = null;
      newValues["independant_hours"] = null;
      newValues["client_observation"] = null;
      newValues["supervision_contact"] = null;
    }
    dispatch(submitExpLog(newValues));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: validateLogHourForm,
  });

  const logCreateSuccess = useSuccess();
  const logCreateErrors = useErrors();

  useEffect(() => {
    if (logCreateSuccess) {
      toast.success("Successfully submitted");
      dispatch(setSuccess(false));
      formik.resetForm();
    }
  }, [logCreateSuccess]);

  useEffect(() => {
    if (logCreateErrors && Array.isArray(logCreateErrors))
      logCreateErrors.forEach((err) => {
        toast.error(err);
      });
  }, [logCreateErrors]);

  console.log("values: ", formik.values);
  // console.log('errors: ',formik.errors);
  // console.log('logCreateErrors: ', logCreateErrors);

  const showSidebar = useShowSidebar();

  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  return (
    <div className="section">
      <div className="columns ">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>

          <p
            className="headingMain headMain"
            style={{ overflowWrap: "break-word" }}
          >
            Log Experience Hours
          </p>
          <div className="loghourDiv">
            <form onSubmit={formik.handleSubmit} className="logHourForm">
              <div className="column mt30 is-five-fifths logForm">
                <label className="label">Experience Type</label>
                <select
                  name="expereince_type"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.expereince_type}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {expTypes.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.expereince_type &&
                    formik.errors.expereince_type) ||
                    ""}
                </span>

                <label className="label">Setting</label>

                <select
                  name="setting"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.setting}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {setting.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.setting && formik.errors.setting) || ""}
                </span>

                <label className="label">Supervisor</label>
                <select
                  name="supervisor"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.supervisor}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {supervisors.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.supervisor && formik.errors.supervisor) ||
                    ""}
                </span>

                <label className="label">Date of Experience</label>
                {/* <div style={{ width: '100%'}}>
              <DatePicker
                name='date_of_experience'
                 placeholderText='mm/dd/yyyy'
                 selected={new Date(formik.values.date_of_experience)}
                onChange={(date, e) => formik.handleChange(e)} 
                className='date-picker'
                />
              </div> */}
                <input
                  className="input is-small"
                  type="date"
                  placeholder=" "
                  name="date_of_experience"
                  value={formik.values.date_of_experience}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.date_of_experience &&
                    formik.errors.date_of_experience) ||
                    ""}
                </span>
                {/* <label className="label">Time of Experience</label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_experience"
                  value={formik.values.time_of_experience}
                  onChange={formik.handleChange}
                />

                <span className="Register__error">
                  {(formik.touched.time_of_experience &&
                    formik.errors.time_of_experience) ||
                    ""}
                </span> */}

                <label className="label">Time of Experience Start</label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_expereince_start"
                  value={formik.values.time_of_expereince_start}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.time_of_expereince_start &&
                    formik.errors.time_of_expereince_start) ||
                    ""}
                </span>
                <label className="label">Time of Experience End</label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_expereince_end"
                  value={formik.values.time_of_expereince_end}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.time_of_expereince_end &&
                    formik.errors.time_of_expereince_end) ||
                    ""}
                </span>

                <label className="label">
                  Unrestricted Hours <Tooltip content={UNRESTRICTED_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="unrestricted_hours"
                  value={formik.values.unrestricted_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.unrestricted_hours &&
                    formik.errors.unrestricted_hours) ||
                    ""}
                </span>

                <label className="label">
                  Restricted Hours <Tooltip content={RESTRICTED_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="restricted_hours"
                  value={formik.values.restricted_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.restricted_hours &&
                    formik.errors.restricted_hours) ||
                    ""}
                </span>

                <label className="label">Experience Hours </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="experience_hours"
                  value={formik.values.experience_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.experience_hours &&
                    formik.errors.experience_hours) ||
                    ""}
                </span>

                <label className="label">
                  Individual <Tooltip content={INDEPENDENT_HELP} /> or group
                  supervision <Tooltip content={GROUP_HELP} /> ?
                </label>

                <select
                  name="individual_or_group"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.individual_or_group}
                  onChange={formik.handleChange}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  <option value="Individual">Individual</option>
                  <option value="Group">Group</option>
                  <option value="No supervision">No supervision</option>
                </select>
                <span className="Register__error">
                  {(formik.touched.individual_or_group &&
                    formik.errors.individual_or_group) ||
                    ""}
                </span>

                {formik.values.individual_or_group ===
                "No supervision" ? null : (
                  <>
                    <label className="label">Method of Supervision</label>
                    <input
                      className="input is-small"
                      type="text"
                      placeholder="Type here ..."
                      name="method_of_supervision"
                      value={formik.values.method_of_supervision}
                      onChange={formik.handleChange}
                    />
                    {/* <select
                      name="method_of_supervision"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.method_of_supervision}
                      onChange={formik.handleChange}
                    >
                      <option value={0} disabled>
                        Select
                      </option>
                      {methods.map((item) => (
                        <option value={Number(item.id)} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <span className="Register__error">
                      {(formik.touched.method_of_supervision &&
                        formik.errors.method_of_supervision) ||
                        ""}
                    </span>

                    <label className="label">Supervision Start Time</label>
                    <input
                      className="input is-small"
                      type="time"
                      placeholder=" "
                      name="supervision_start_time"
                      value={formik.values.supervision_start_time}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervision_start_time &&
                        formik.errors.supervision_end_time) ||
                        ""}
                    </span>

                    <label className="label">Supervision End Time</label>
                    <input
                      className="input is-small"
                      type="time"
                      placeholder=" "
                      name="supervision_end_time"
                      value={formik.values.supervision_end_time}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervision_end_time &&
                        formik.errors.supervision_end_time) ||
                        ""}
                    </span>

                    <label className="label">Supervised Hours </label>
                    <input
                      className="input is-small"
                      type="number"
                      placeholder="Type here ..."
                      name="supervised_hours"
                      value={formik.values.supervised_hours}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervised_hours &&
                        formik.errors.supervised_hours) ||
                        ""}
                    </span>

                    <label className="label">
                      Independent Hours
                      <Tooltip content={INDEPENDENT_HELP} />{" "}
                    </label>
                    <input
                      className="input is-small"
                      type="number"
                      placeholder="Type here ..."
                      name="independant_hours"
                      value={formik.values.independant_hours}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.independant_hours &&
                        formik.errors.independant_hours) ||
                        ""}
                    </span>

                    <label className="label">
                      Client Observation?
                      <Tooltip content={OBSERVATION_HELP} />
                    </label>
                    <input
                      className="input is-small"
                      type="text"
                      placeholder="Type here ..."
                      name="client_observation"
                      value={formik.values.client_observation}
                      onChange={formik.handleChange}
                    />
                    {/* <select
                      name="client_observation"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.client_observation}
                      onChange={formik.handleChange}
                    >
                      <option value={0} disabled>
                        Select
                      </option>
                      {obsevations.map((item) => (
                        <option value={Number(item.id)} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <span className="Register__error">
                      {(formik.touched.client_observation &&
                        formik.errors.client_observation) ||
                        ""}
                    </span>

                    <label className="label">Supervision contacts</label>
                    <select
                      name="supervision_contact"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.supervision_contact}
                      onChange={formik.handleChange}
                    >
                      <option value={0} disabled>
                        Select
                      </option>
                      {contacts.map((item) => (
                        <option value={Number(item.id)} key={item.id}>
                          {item.contact}
                        </option>
                      ))}
                    </select>
                    <span className="Register__error">
                      {(formik.touched.supervision_contact &&
                        formik.errors.supervision_contact) ||
                        ""}
                    </span>
                  </>
                )}

                <label className="label">Task list items documented</label>
                {/* <select
                  name="task"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.task}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {tasks.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name.split(" ")[0]}
                    </option>
                  ))}
                </select> */}
                <MultipleSelect
                  name="task"
                  value={formik.values.task}
                  handleChange={formik.handleChange}
                  items={taskNames}
                />

                <span className="Register__error">
                  {(formik.touched.task && formik.errors.task) || ""}
                </span>

                <label className="label">Experience Notes</label>
                <textarea
                  className="textarea"
                  placeholder="Type here..."
                  name="experience_note"
                  value={formik.values.experience_note}
                  onChange={formik.handleChange}
                ></textarea>
                <span className="Register__error">
                  {(formik.touched.experience_note &&
                    formik.errors.experience_note) ||
                    ""}
                </span>

                <button
                  className="button is-two-fifths is-large is-rounded dashBtn mt30"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>

            <MonthCard />
          </div>
        </div>
      </div>
    </div>
  );
};

const MonthCard = () => {
  const checks = useMonthlyChecks();

  useDispatchEffect(fetchMonthlyCheck, null, checks === null);

  return (
    <div className="mt30 month-card">
      <div className="month">{moment(new Date()).format("MMMM YYYY")}</div>
      <Stack width="100%" direction="row">
        <div className="header">Supervised: 2,000</div>
        <div className="header">Concentrated: 1,500</div>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack width="50%" spacing={2} p={3} borderRight="1px solid #B7B7B7">
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
        <Stack width="50%" spacing={2} p={3}>
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
      </Stack>
    </div>
  );
};

const CheckItem = ({ checked, label }) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <CheckCircleIcon
        sx={{
          color: checked ? "#D37498" : "#FFF",
          fontSize: "20px",
          mr: "16px",
        }}
      />
      <Typography
        className="check-label"
        style={{ color: checked ? "#D37498" : "#939597" }}
      >
        {label}
      </Typography>
    </Stack>
  );
};
export default LogHour;
