import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import StudentSidebar from '../../components/StudentSidebar'
import DashboardSubscription from "./dashboardSubscription";
import SubscriptionScreen2 from "./subscriptionScreen2Dashboard";
import PaymentDashboardScreen from "./paymentDashboardScreen";
import LogHour from "./logHour";
import Dashboard from './dashboard';
import Flagged from "./flagged";
import Upload from "./upload";
import MonthlyDocument from './monthlyDocument';
import EditAndResend from './editAndResend';
import ProfileScreen from "../profile";
import PromptScreen from "..//prompt";
import TermsAndCondition from "../termAndcondition";
import Privacy from "../privacyPolicy";
import PasswordChangeProfile from '../dashboardChangepswd';
import { useIsSupervisor } from '../../redux/reducers/AuthReducer';
import PageUnauthorized from '../PageUnauthorized';
import { fetchProfile } from '../../redux/sagas/profile/fetchSaga';
import { useDispatchEffect } from '../../utils/hooks';
import { useIsLoading, useUserProfile } from '../../redux/reducers/ProfileReducer';
import { validateTrialPeriod } from '../../utils/validation';
import LoadingSpinner from '../../components/LoadingSpinner';
import SubscriptionDialog from '../../components/SubscriptionDialog';


export default function StudentDashboard() {
  const isSupervisor = useIsSupervisor();
  const user = useUserProfile();
  const isLoading = useIsLoading(); 

  useDispatchEffect(fetchProfile, null, true);

  const isTrialValid = user.is_paid === false ? validateTrialPeriod(new Date(user.date_joined)) : true;

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if(!isTrialValid)
      setShowDialog(true)
  }, [isTrialValid]);

  if(isLoading)
  return (
    <LoadingSpinner style={{ width: '100%'}} />
  )

  if(isSupervisor === true)
    return <PageUnauthorized />

  return (
    <div className="sectiondashboard">
        <StudentSidebar />
        <Routes>
          <Route 
            path="/dashboard" 
            element={<Dashboard />} 
            />
          <Route 
            path="/logHour" 
            element={<LogHour />} 
            />
          <Route
            path="/monthly-document"
            element={<MonthlyDocument />}
            />
          <Route
            path="/dashboard-subscription"
            element={<DashboardSubscription />}
            />
          <Route
            path="/subscription-screen-dashbard"
            element={<SubscriptionScreen2 />}
            />
          <Route
            path="/payment-screen-dashbard"
            element={<PaymentDashboardScreen />}
            />
          <Route 
            path="/flagged" 
            element={<Flagged />} 
            />
          <Route 
            path="/upload" 
            element={<Upload />} 
            />
          <Route 
            path="/edit-resend" 
            element={<EditAndResend />} 
            />
          <Route 
            path="/profile-screen" 
            element={<ProfileScreen />} 
            />
          <Route 
            path="/prompt-screen" 
            element={<PromptScreen />} 
            />
          <Route
            path="/terms-condition"
            element={<TermsAndCondition />}
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/password-change-profile"
            element={<PasswordChangeProfile />}
          />

        </Routes>

        <SubscriptionDialog isVisible={showDialog} closeDialog={() => setShowDialog(false)}/>

    </div>

  )
}
