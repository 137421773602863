import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName) {
  return personName.indexOf(name) === -1 ? {
    fontWeight: 400,
    backgroundColor: 'transparent'
  } : {
    fontWeight: 700,
    backgroundColor: '#c6707014'
  };
}

export default function MultipleSelect({ name, value, handleChange, items=[] }) {

  return (
    <div>
      <FormControl sx={{ width: '100%', p: 0, mt: '10px' }}>
        <Select
          multiple
          name={name}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput sx={{ border: '1px solid #4B2929'}} />}
          MenuProps={MenuProps}
          style={{ width: '100%' }}
          sx={{
            fontSize: '13px',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#D37498',
            borderRadius: '5px',
          }}

        >
          {items.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, value)}
              sx={{
                fontSize: '13px',
                lineHeight: '15px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#D37498',    
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
