import moment from "moment";
import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useUser } from "../../../redux/reducers/AuthReducer";
import {
  useAccruedData,
  useSkills,
  useTasks,
  useSuccess as monthlyDocSuccess,
} from "../../../redux/reducers/MonthlyDocReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import {
  useData,
  useIsLoading,
  useSuccess,
} from "../../../redux/reducers/TraineesReducer";
import {
  fetchAccrued,
  fetchAccruedByDate,
} from "../../../redux/sagas/monthlyDocument/fetchAccruedSaga";
import { fetchSkills } from "../../../redux/sagas/monthlyDocument/fetchSkillsSaga";
import { fetchTasks } from "../../../redux/sagas/monthlyDocument/fetchTasksSaga";
import { submitDocument } from "../../../redux/sagas/monthlyDocument/submitDocSaga";
import { fetchAllTrainees } from "../../../redux/sagas/trainees/fetchAllSaga";
import { calculateWeeks, useDispatchEffect } from "../../../utils/hooks";
import "./styles.css";

const MonthlyDocument = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();
  const trainees = useData();
  const success = useSuccess();
  const isLoading = useIsLoading();

  const [trainee, setTrainee] = useState();

  useDispatchEffect(fetchAllTrainees, null, true);

  useEffect(() => {
    if (success) setTrainee(trainees[0]?.id);
  }, [success]);

  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState(0);

  const weeks = calculateWeeks(date);

  console.log("trainee: ", trainee);

  const onPrevMonth = () => {
    const newDate = moment(date).subtract(1, "month");
    setDate(newDate.toDate());
  };
  const onNextMonth = () => {
    const newDate = moment(date).add(1, "month");
    setDate(newDate.toDate());
  };
  const onWeekChange = (weekNo) => {
    setWeek(weekNo);
  };

  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  return (
    <div className="section monthly-document" style={{ overflowX: "auto" }}>
      <div className="columns">
        <div className="column is-one-fifths">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <p className="headingMain">Monthly document</p>
          <label className="label">Student Name</label>
          <select
            className="select dropdownCustom is-normal"
            value={trainee}
            onChange={(e) => setTrainee(e.target.value)}
          >
            {trainees.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="section2">
        <div className="columns">
          <div className="column">
            <p className="headingMain">
              <button className="backlink" onClick={onPrevMonth}>
                <IoIosArrowBack
                  size={24}
                  style={{ margin: "0 20px", marginLeft: 0 }}
                />
              </button>
              <select
                className="select selectBtn dropdownCustom2 mt is-normal"
                style={{ height: "28px" }}
                value={week}
                onChange={(e) => onWeekChange(e.target.value)}
              >
                {weeks.map((week, index) => (
                  <option key={index} value={index}>
                    {`${moment().month(date.getMonth()).format("MMMM")} (${
                      index + 1
                    }) / ${date.getFullYear()}`}
                  </option>
                ))}
              </select>
              <button
                className=" backlink"
                onClick={onNextMonth}
                style={{ margin: "0 20px" }}
              >
                <IoIosArrowForward size={24} style={{ margin: "0 20px" }} />
              </button>
            </p>

            <AgendaForm trainee={trainee} date={date} week={weeks[week]} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AgendaForm = ({ trainee, date, week }) => {
  const dispatch = useDispatch();
  const trainees = useData();
  const accruedData = useAccruedData();
  const tasks = useTasks();
  const skills = useSkills();
  const success = monthlyDocSuccess();
  const { total, weekly } = accruedData;
  const supervisor = useUser();

  const supervisee = trainees.find((t) => t.id == trainee);
  let traineeTasks = [];
  tasks
    .filter((t) => t.user.id == trainee)
    .forEach((t) => {
      traineeTasks = traineeTasks.concat(t.task);
    });
  // traineeTasks = traineeTasks.map(t => t.name);

  console.log("supervisee: ", supervisee);

  const [values, setValues] = useState({
    supervisee: supervisee?.name,
    supervisor: supervisor?.name,
    startTime: "",
    endTime: "",
    date: "",
    format: "In Person",
    fieldHoursCheck: false,
    accruedData: null,
    supervisedFieldwork: false,
    practicum: false,
    intensivePracticum: false,
    superviseeCheck: false,
    superviseeFeedback: "",
    observationChecK: "",
    observationDate: "",
    skills: 2,
    observationFeedback: "",
    clinicalPracticeCheck: false,
    type: "",
    completed: false,
    notCompleted: false,
    inPerson: false,
    online: false,
    clinicalPracticeFeedback: "",
    taskListReviewCheck: false,
    tasks: [],
  });

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };
  const handleCheckChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.checked });
  };
  const handleRadioGroup1 = (prop) => (e) => {
    setValues({
      ...values,
      supervisedFieldwork: false,
      practicum: false,
      intensivePracticum: false,
      [prop]: e.target.checked,
    });
  };
  const handleRadioGroup2 = (prop) => (e) => {
    setValues({
      ...values,
      completed: false,
      notCompleted: false,
      [prop]: e.target.checked,
    });
  };
  const handleRadioGroup3 = (prop) => (e) => {
    setValues({
      ...values,
      inPerson: false,
      online: false,
      [prop]: e.target.checked,
    });
  };
  const handleTaskChange = (task, index) => {
    const newTasks = [...values.tasks];
    newTasks[index] = task;
    setValues({ ...values, tasks: newTasks });
  };

  const onSubmit = () => {
    const payload = {
      task: values.skills,
      data: values,
      date: moment().format("YYYY-MM-DD"),
      supervisior: supervisor?.id,
      user: supervisee?.id,
    };
    dispatch(submitDocument(payload));
  };

  console.log("values: ", values);

  useDispatchEffect(fetchAccruedByDate, { u_id: supervisee?.id, week }, week.from || supervisee?.id);
  useDispatchEffect(fetchAccruedByDate, { u_id: supervisee?.id, week }, supervisee);
  useDispatchEffect(fetchAccrued, supervisee?.id, supervisee);
  useDispatchEffect(fetchTasks, null, tasks?.length === 0);
  useDispatchEffect(fetchSkills, null, skills?.length === 0);

  useEffect(() => {
    const supervisee = trainees.find((t) => t.id == trainee);
    setValues({ ...values, supervisee: supervisee?.name });
  }, [trainee]);

  useEffect(() => {
    setValues({ ...values, accruedData: { total, weekly } });
  }, [total, weekly]);

  return (
    <div className="column mt40" style={{ marginLeft: "-20px" }}>
      <div className="headerChart">
        <p>INDIVIDUAL SUPERVISION MEETING AGENDA</p>
      </div>
      <div className="box">
        <div className="agendaSec1 mt10">
          <div>
            <p>
              <span>Supervisee: </span>
              <span>
                <input
                  className="contactAgendaInput"
                  type="text"
                  placeholder="Type here..."
                  value={values.supervisee}
                  onChange={handleChange("supervisee")}
                />
              </span>
            </p>
            <p>
              <span>Start Time: </span>
              <span>
                <input
                  className="contactAgendaInput"
                  type="time"
                  placeholder="00:00 AA"
                  value={values.startTime}
                  onChange={handleChange("startTime")}
                />
              </span>
            </p>
            <p>
              <span>Date: </span>
              <span>
                <input
                  className="contactAgendaInput"
                  type="date"
                  placeholder="MM/DD/YYYY"
                  value={values.date}
                  onChange={handleChange("date")}
                />
              </span>
            </p>
          </div>
          <div className="ml160">
            <p>
              <span>Supervisor: </span>
              <span>
                <input
                  className="contactAgendaInput"
                  type="text"
                  placeholder="Type here..."
                  value={values.supervisor}
                  onChange={handleChange("supervisor")}
                />
              </span>
            </p>
            <p>
              <span>End Time: </span>
              <span>
                <input
                  className="contactAgendaInput"
                  type="time"
                  placeholder="00:00 AA"
                  value={values.endTime}
                  onChange={handleChange("endTime")}
                />
              </span>
            </p>
            <p>
              <span>Format: </span>
              <span>
                <select
                  className="select dropdownCustomize2 mt is-normal"
                  style={{ height: "18px" }}
                  value={values.format}
                  onChange={handleChange("format")}
                >
                  <option value={"In Person"}>In Person</option>
                  <option value={"Online"}>Online</option>
                </select>
              </span>
            </p>
          </div>
        </div>

        <div style={{ height: "15px" }} />

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkSize"
            value={values.fieldHoursCheck}
            onChange={handleCheckChange("fieldHoursCheck")}
          />
          <span>Fieldwork Hours Check</span>
        </label>
        <div className="agendasec2 mt20" style={{ margin: "15px 40px" }}>
          <AccruedTable
            title={`Month to Date:  ${moment()
              .month(date.getMonth())
              .format("MMMM")}`}
            data={weekly}
          />
          <AccruedTable title={`Total Accrued`} data={total} />
          <div style={{ marginRight: "20px" }}>
            <p className="checkText">
              <input
                type="radio"
                name="checkbox1"
                className="checkSize"
                value={values.supervisedFieldwork}
                onChange={handleRadioGroup1("supervisedFieldwork")}
              />
              Supervised Fieldwork
            </p>
            <p className="checkText">
              <input
                type="radio"
                name="checkbox1"
                className="checkSize"
                value={values.practicum}
                onChange={handleRadioGroup1("practicum")}
              />
              Practicum
            </p>
            <p className="checkText">
              <input
                type="radio"
                name="checkbox1"
                className="checkSize"
                value={values.intensivePracticum}
                onChange={handleRadioGroup1("intensivePracticum")}
              />
              Intensive Practicum
            </p>
          </div>
        </div>
        <div style={{ height: "15px" }} />

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkSize"
            value={values.superviseeCheck}
            onChange={handleCheckChange("superviseeCheck")}
          />
          <span>Supervisee Check</span>
        </label>

        <div className="column mt20" style={{ margin: "0px 30px" }}>
          <label style={{ lineHeight: "30px" }}>
            Supervisee questions and feedback:
          </label>
          <textarea
            className="textarea  "
            placeholder="Type here..."
            value={values.superviseeFeedback}
            onChange={handleChange("superviseeFeedback")}
          ></textarea>
        </div>
        <div style={{ height: "15px" }} />

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkSize"
            value={values.observationChecK}
            onChange={handleCheckChange("observationChecK")}
          />
          <span>Observation Check</span>
        </label>

        <div className="mt20 agendasec3" style={{ margin: "20px 50px" }}>
          <p>
            Observation date:
            <input
              type="date"
              className="inputagenda"
              value={values.observationDate}
              onChange={handleChange("observationDate")}
            />
          </p>
          <br />
          <label className="mt10">Skills demonstrated:</label>
          <br />
          <select
            className="select dropdownCustomize mt is-normal"
            value={values.skills}
            onChange={handleChange("skills")}
          >
            {skills.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <p className="mt30">Feedback:</p>
          <textarea
            className="textarea  "
            placeholder="Type here..."
            value={values.observationFeedback}
            onChange={handleChange("observationFeedback")}
          ></textarea>
        </div>
        <div style={{ height: "15px" }} />

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkSize"
            value={values.clinicalPracticeCheck}
            onChange={handleChange("clinicalPracticeCheck")}
          />
          <span>Clinical Practice Check</span>
        </label>

        <div className="mt30" style={{ margin: "20px 50px" }}>
          <p>
            Type:
            <input
              type="text"
              className="inputagenda"
              value={values.type}
              onChange={handleChange("type")}
            />
          </p>
          <div className="check mt10">
            <p>
              <input
                type="radio"
                name="checkGroup2"
                className="checkSize"
                value={values.completed}
                onChange={handleRadioGroup2("completed")}
              />
              Completed
            </p>
            <p>
              <input
                type="radio"
                name="checkGroup3"
                className="checkSize"
                value={values.inPerson}
                onChange={handleRadioGroup3("inPerson")}
              />
              In person
            </p>
            <p>
              <input
                type="radio"
                name="checkGroup2"
                className="checkSize"
                value={values.notCompleted}
                onChange={handleRadioGroup2("notCompleted")}
              />
              Not completed
            </p>
            <p>
              <input
                type="radio"
                name="checkGroup3"
                className="checkSize"
                value={values.online}
                onChange={handleRadioGroup3("online")}
              />
              Online
            </p>
          </div>
          <p className="mt30" style={{ lineHeight: "30px" }}>
            Feedback:
          </p>
          <textarea
            className="textarea  "
            placeholder="Type here..."
            value={values.clinicalPracticeFeedback}
            onChange={handleChange("clinicalPracticeFeedback")}
          ></textarea>
        </div>
        <div style={{ height: "15px" }} />

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkSize"
            value={values.taskListReviewCheck}
            onChange={handleCheckChange("taskListReviewCheck")}
          />
          <span>Task List Review Check</span>
        </label>

        <div className="mt30 agendaTable" style={{ margin: "20px 20px" }}>
          <table>
            <tr className="noBorder">
              <th
                rowSpan="2"
                align="left"
                className="agendaTableHeader"
                style={{ textAlign: "left", paddingLeft: "16px" }}
              >
                Task
              </th>
              <th colSpan="2" className="agendaTableHeader">
                Assessment
              </th>
              <th rowSpan="2" className="agendaTableHeader">
                Feedback
              </th>
              <th rowSpan="2" className="agendaTableHeader">
                Review
              </th>
            </tr>
            <tr>
              <th className="agendaTableText">Can Demonstrate</th>
              <th className="agendaTableText"> Can Explain</th>
            </tr>
            {traineeTasks.map((task, i) => (
              <Task
                name={task?.name}
                onChange={(task) => handleTaskChange(task, i)}
                key={`task-${i}`}
              />
            ))}
          </table>
        </div>

        <div className="column is-two-fifths mt40">
          <a className="button is-large is-rounded dashBtn" onClick={onSubmit}>
            Save Doc
          </a>
        </div>
      </div>
    </div>
  );
};

const AccruedTable = ({ title, data }) => {
  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = data;

  return (
    <div>
      <table className="agenda-table">
        <tr className="barTableHeader">
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <td>Independent Hours</td>
          <td className="colSize1">{independent_hours || "0"}</td>
        </tr>
        <tr>
          <td>Unrestricted Hours</td>
          <td className="colSize1">{unrestricted_hours || "0"}</td>
        </tr>
        <tr>
          <td>% Unrestricted</td>
          <td className="colSize1">{percent_unrestricted?.toFixed(0) || "0"}%</td>
        </tr>
        <tr>
          <td># Supervision Contacts</td>
          <td className="colSize1">{supervision_contacts?.toFixed(0) || "0"}</td>
        </tr>
        <tr>
          <td>% Supervision</td>
          <td className="colSize1">{percent_supervision?.toFixed(0) || "0"}%</td>
        </tr>
      </table>
    </div>
  );
};

const Task = ({ name, onChange }) => {
  const [task, setTask] = useState({
    name,
    canDemonstrate: false,
    canExplain: false,
    feedback: "",
    review: "",
  });
  const handleChange = (prop) => (e) => {
    const newTask = { ...task, [prop]: e.target.value };
    setTask(newTask);
  };
  const handleCheckChange = (prop) => (e) => {
    const newTask = { ...task, [prop]: e.target.checked };
    setTask(newTask);
    onChange(newTask);
  };

  return (
    <tr>
      <td style={{ width: "30%", textAlign: "left", paddingLeft: "16px" }}>
        {name || ""}
      </td>
      <td style={{ width: "10%" }}>
        <input
          type="checkbox"
          className=""
          value={task.canDemonstrate || false}
          onChange={handleCheckChange("canDemonstrate")}
        />
      </td>
      <td style={{ width: "10%" }}>
        <input
          type="checkbox"
          className=""
          value={task.canExplain || false}
          onChange={handleCheckChange("canExplain")}
        />
      </td>
      <td style={{ width: "20%" }}>
        <input
          type="text"
          className="inputagenda3"
          value={task.feedback || ""}
          onChange={handleChange("feedback")}
          onBlur={() => onChange(task)}
        />
      </td>
      <td style={{ width: "20%" }}>
        <input
          type="text"
          className="inputagenda3"
          value={task.review || ""}
          onChange={handleChange("review")}
          onBlur={() => onChange(task)}
        />
      </td>
    </tr>
  );
};
export default MonthlyDocument;
