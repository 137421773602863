import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  useFormData,
  useIsLoading,
  useLogs,
} from "../../../redux/reducers/ExpLogReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import { fetchAll } from "../../../redux/sagas/experienceLog/fetchAllSaga";
import { fetchDoc } from "../../../redux/sagas/monthlyDocument/fetchDocSaga";
import { calculateWeeks, useDispatchEffect } from "../../../utils/hooks";
import "./monthlyDocument.css";
import moment from "moment";
import { useDocs } from "../../../redux/reducers/MonthlyDocReducer";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import { useRef } from "react";

import { renderToString } from "react-dom/server";
import Test from "../../../components/PDF/Test";

const TASKS_TABLE = [
  [
    "A-1",
    "B-1",
    "B-8",
    "C-1",
    "D-1",
    "E-1",
    "F-1",
    "G-1",
    "G-12",
    "H-1",
    "I-1",
  ],
  [
    "A-2",
    "B-2",
    "B-9",
    "C-2",
    "D-2",
    "E-2",
    "F-2",
    "G-2",
    "G-13",
    "H-2",
    "I-2",
  ],
  [
    "A-3",
    "B-3",
    "B-10",
    "C-3",
    "D-3",
    "E-3",
    "F-3",
    "G-3",
    "G-14",
    "H-3",
    "I-3",
  ],
  [
    "A-4",
    "B-4",
    "B-11",
    "C-4",
    "D-4",
    "E-4",
    "F-4",
    "G-4",
    "G-15",
    "H-4",
    "I-4",
  ],
  [
    "A-5",
    "B-5",
    "B-12",
    "C-5",
    "D-5",
    "E-5",
    "F-5",
    "G-5",
    "G-16",
    "H-5",
    "I-5",
  ],
  [
    null,
    "B-6",
    "B-13",
    "C-6",
    "D-6",
    "E-6",
    "F-6",
    "G-6",
    "G-17",
    "H-6",
    "I-6",
  ],
  [null, "B-7", "B-14", "C-7", null, "E-7", "F-7", "G-7", "G-18", "H-7", "I-7"],
  [null, null, "B-15", "C-8", null, null, "F-8", "G-8", "G-19", "H-8", "I-8"],
  [null, null, null, "C-9", null, null, "F-9", "G-9", "G-20", "H-9", null],
  [null, null, null, "C-10", null, null, null, "G-10", "G-21", null, null],
  [null, null, null, "C-11", null, null, null, "G-11", "G-22", null, null],
];
const MonthlyDocument = () => {
  const navigate = useNavigate();

  const logs = useLogs();
  const isLoading = useIsLoading();

  useDispatchEffect(fetchAll, null, true);

  const tasks = {};
  let total_restricted_hrs = 0;
  let total_unrestricted_hrs = 0;
  let total_supervisor_hrs = 0;

  for (const log of logs) {
    const taskCodes = log.task?.map((t) => t.name.split(" ")[0]);
    for (const taskCode of taskCodes) {
      if (taskCode) {
        if (tasks[taskCode] && Array.isArray(tasks[taskCode]))
          tasks[taskCode].push(log?.date_of_experience);
        else tasks[taskCode] = [log?.date_of_experience];
      }
    }
    total_restricted_hrs += Number(log?.restricted_hours);
    total_unrestricted_hrs += Number(log?.unrestricted_hours);
  }
  total_supervisor_hrs = total_restricted_hrs + total_unrestricted_hrs;

  console.log("tasks: ", tasks);
  const renderTaskDates = (task) => {
    const taskDates = tasks[task];
    return [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
      const date =
        taskDates && taskDates[i - 1]
          ? taskDates[i - 1].split("-").reverse().join("/")
          : "dd/mm/yyyy";
      const disabled = date === "dd/mm/yyyy";
      return (
        <option disabled={disabled} key={task + i}>
          {i}. {date}
        </option>
      );
    });
  };
  const renderTaskColor = (task) => {
    const taskDates = tasks[task];
    if (taskDates?.length > 0 && taskDates?.length <= 3) return "#F9E18A";
    else if (taskDates?.length > 3 && taskDates?.length <= 6) return "#75D6D1";
    else if (taskDates?.length > 6 && taskDates?.length <= 10) return "#D37498";
    else return "#FFFFFF";
  };

  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();

  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  return (
    <div className="section">
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          {/* {TestElement()} */}
          <p className="headingMain">Monthly Document</p>
          <p className=" mt30 heading2nd" style={{ marginBottom: "10px" }}>
            Task List
          </p>
          <div>
            <div className="columns">
              <div className="column is-four-fifths monthlyTableMove">
                <table className="monthlyTable" style={{ border: "none" }}>
                  {TASKS_TABLE.map((row, i) => (
                    <tr key={"row-" + i}>
                      {row.map((cell, j) => (
                        <td key={`cell-${i}${j}`}>
                          {cell && (
                            <select
                              style={{ background: renderTaskColor(cell) }}
                              className="select selectBtn dropdownCustom mt is-normal"
                            >
                              <option>{cell.replace("-", "")}</option>
                              {renderTaskDates(cell)}
                            </select>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <div className="HRline" />
          <div className="columns mt10">
            <div className=" column secondSectionMonthlyDocumnet">
              <div>
                <p>
                  Total Restricted
                  Hours:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {total_restricted_hrs}
                </p>
                <p>
                  Total Unrestricted
                  Hours:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {total_unrestricted_hrs}
                </p>
                <p>
                  Total Supervisor
                  Hours:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {total_supervisor_hrs}
                </p>
              </div>
              <div className="colordtext">
                <div className="ml160">
                  <button className="clr1"></button>
                  <span>1-3 opportunities with the task list item</span>
                </div>
                <div className="ml160">
                  <button className="clr2"></button>
                  <span>4-6 opportunities with the task list item</span>
                </div>
                <div className="ml160">
                  <button className="clr3"></button>
                  <span>7-10 opportunities with the task list item</span>
                </div>
                <div className="ml160">
                  <h1 className="Heading mt20">
                    <b>&nbsp;&nbsp;New Estimated Date of Completion:</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>08/11/2023</b>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="HRline mb30" />

          <AgendaForm />
        </div>
      </div>
    </div>
  );
};

const AgendaForm = () => {
  const { tasks: skills } = useFormData();
  const docs = useDocs();
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState(0);
  const [close, setClose] = useState(true);
  const weeks = calculateWeeks(date);
  const weekDates = weeks[week];

  console.log("weekDates: ", weekDates);

  const initialValues = {
    supervisee: "",
    supervisor: "",
    startTime: "",
    endTime: "",
    date: "",
    format: "",
    fieldHoursCheck: false,
    accruedData: null,
    supervisedFieldwork: false,
    practicum: false,
    intensivePracticum: false,
    superviseeCheck: false,
    superviseeFeedback: "",
    observationChecK: "",
    observationDate: "",
    skills: 2,
    observationFeedback: "",
    clinicalPracticeCheck: false,
    type: "",
    completed: false,
    notCompleted: false,
    inPerson: false,
    online: false,
    clinicalPracticeFeedback: "",
    taskListReviewCheck: false,
    tasks: [],
  };
  const [values, setValues] = useState(initialValues);

  console.log("values: ", values);
  const onPrevMonth = () => {
    const newDate = moment(date).subtract(1, "month");
    setDate(newDate.toDate());
  };
  const onNextMonth = () => {
    const newDate = moment(date).add(1, "month");
    setDate(newDate.toDate());
  };
  const onWeekChange = (weekNo) => {
    setWeek(weekNo);
    // const weekStart = moment(weeks[weekNo].from, 'YYYY-MM-DD'); console.log('weekStart: ', weekStart);
    // const weekSelected = weekStart.isoWeek(); console.log('weekSelected: ', weekSelected);
    // const document = docs.find(doc => {
    //   const weekDoc = moment(doc.date, 'YYYY-MM-DD').isoWeek();
    //   return weekDoc === weekSelected;
    // });
    // console.log('document: ', document);
    // setValues({ ...values, ...document?.data });
  };

  const handleChange = (prop) => (e) => {};
  const handleCheckChange = (prop) => (e) => {};
  const handleRadioGroup1 = (prop) => (e) => {};
  const handleRadioGroup2 = (prop) => (e) => {};
  const handleRadioGroup3 = (prop) => (e) => {};

  const handleClose = () => {
    setClose(!close);
  };

  useDispatchEffect(fetchDoc, null, true);

  useEffect(() => {
    const weekStart = moment(weekDates.from, "YYYY-MM-DD");
    const weekSelected = weekStart.isoWeek();
    console.log("weekSelected: ", weekSelected);
    const document =
      docs.find((doc) => {
        const weekDoc = moment(doc.date, "YYYY-MM-DD").isoWeek();
        return weekDoc === weekSelected;
      }) || {};
    console.log("document: ", document);
    if (document.date) {
      setValues({ ...(document?.data || initialValues) });
      setClose(false);
    }
  }, [weekDates.from]);

  // const toPdf = () => {
  //   const targetComponent = document.getElementById('monthly-document');
  //   if(targetComponent) {
  //     const doc = new JsPDF({
  //       orientation: 'landscape',
  //       unit: 'mm',
  //       format: 'a4',
  //       putOnlyUsedFonts: true
  //     });
  //     doc.html(, {
  //       callback: function (doc) {
  //         doc.save('monthly-document.pdf');
  //       },
  //       x: 10,
  //       y: 10,
  //       autoPaging: false,
  //       jsPDF: doc,
  //     });
  //   }
  //  }

  // const toPdf = ()=>{
  //   html2canvas(document.getElementById("test")).then(canvas => {
  //     console.log(canvas)
  //     // document.body.appendChild(canvas);  // if you want see your screenshot in body.
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new JsPDF();
  //     pdf.addImage(imgData, 'PNG', 0, 0);
  //     // pdf.save("download.pdf");
  //   });
  // }

  const toPdf = () => {
    const element = targetRefPdf.current;
    var scale = 2;
    domtoimage
      .toPng(element, {
        width: element.clientWidth * scale,
        height: element.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      })
      .then(function (dataUrl) {
        const pdf = new jsPDF("p", "pt", "a4");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(dataUrl, "JPEG", 0, 0, width, height);
        pdf.save(`${new Date().toISOString()}.pdf`);
      });
    // const dataUrl = exportToPng(element);
    // console.log(dataUrl);
    // const pdf = new jsPDF("p", "pt", "a4");
    // pdf.fromHTML(element);

    // pdf.save(`${new Date().toISOString()}.pdf`);
    //
  };

  const toPdfComponent = () => {
    const string = renderToString(<Test />);
    // console.log(test)
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.fromHTML(string);
    pdf.save("text");
  };

  const targetRefPdf = useRef();

  return (
    <>
      {/* Dummy Instructions  */}
      {/* <div
      // ref={targetRefPdf}
      >
        <div>Header</div>
        <div className="box">
          <p>REF</p>
        </div>
      </div> */}
      {/* <div>
        <button
          className="button is-rounded  mt30"
          style={{
            float: "right",
            marginRight: "10px",
            color: "white",
            backgroundColor: "#D37498",
          }}
          onClick={toPdf}
        >
          {"Download PDF With Ref"}
        </button>
        </div> */}
      {/* <button
          className="button is-rounded  mt30"
          style={{
            float: "right",
            marginRight: "10px",
            color: "white",
            backgroundColor: "#D37498",
          }}
          onClick={toPdfComponent}
        >
          {"Download PDF With Component"}
        </button> */}

      {/* Instructions END */}

      <div className="section2 monthly-document">
        <div className="columns">
          <div className="column">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                className="headingMain mt30"
                style={{ marginLeft: "12px", width: "100%" }}
              >
                <button className="backlink" onClick={onPrevMonth}>
                  <IoIosArrowBack
                    size={24}
                    style={{ margin: "0 20px", marginLeft: 0 }}
                  />
                </button>
                <select
                  className="select selectBtn dropdownCustom2  is-normal"
                  style={{ height: "28px" }}
                  value={week}
                  onChange={(e) => onWeekChange(e.target.value)}
                >
                  {weeks.map((week, index) => (
                    <option key={index} value={index}>
                      {`${moment().month(date.getMonth()).format("MMMM")} (${
                        index + 1
                      }) / ${date.getFullYear()}`}
                    </option>
                  ))}
                </select>
                <button
                  className=" backlink"
                  onClick={onNextMonth}
                  style={{ margin: "0 20px" }}
                >
                  <IoIosArrowForward size={24} style={{ margin: "0 20px" }} />
                </button>
                {!close > 0 && (
                  <button
                    className="button is-rounded"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      color: "white",
                      backgroundColor: "#D37498",
                    }}
                    onClick={toPdf}
                  >
                    Download PDF
                  </button>
                )}
              </p>

              {values.date.length > 0 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button   is-rounded  mt30"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      color: "white",
                      backgroundColor: "#D37498",
                    }}
                    onClick={handleClose}
                  >
                    {close ? "Open" : "Close"}
                  </button>
                  <button
                    className="button is-rounded  mt30"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      color: "white",
                      backgroundColor: "#D37498",
                    }}
                    onClick={toPdf}
                  >
                    {"Download PDF"}
                  </button>
                </div>
              )}
            </div>

            {!close && (
              <div className="column mt40" id="targetRefPdf" ref={targetRefPdf}>
                <div className="headerChart" id={"monthly-document"}>
                  <p id={"monthly-document"}>SUPERVISION CONTACT AGENDA</p>
                </div>
                <div className="box">
                  <div className="agendaSec1 mt10">
                    <div>
                      <p>
                        <span>Supervisee: </span>
                        <span className="contactAgendaInput">
                          {values.supervisee}
                        </span>
                      </p>
                      <p>
                        <span>Start Time: </span>
                        <span className="contactAgendaInput">
                          {values.startTime}
                        </span>
                      </p>
                      <p>
                        <span>Date: </span>
                        <span className="contactAgendaInput">
                          {values.date}
                        </span>
                      </p>
                    </div>
                    <div className="ml160">
                      <p>
                        <span>Supervisor: </span>
                        <span className="contactAgendaInput">
                          {values.supervisor}
                        </span>
                      </p>
                      <p>
                        <span>End Time: </span>
                        <span className="contactAgendaInput">
                          {values.endTime}
                        </span>
                      </p>
                      <p>
                        <span>Format: </span>
                        <span className="contactAgendaInput">
                          {values.format}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.fieldHoursCheck}
                      onChange={handleCheckChange("fieldHoursCheck")}
                    />
                    <span>Fieldwork Hours Check</span>
                  </label>
                  <div
                    className="agendasec2 mt20"
                    style={{ margin: "15px 40px" }}
                  >
                    <AccruedTable
                      title={`Month to Date:  ${moment()
                        .month(date.getMonth())
                        .format("MMMM")}`}
                      data={values?.accruedData?.weekly || {}}
                    />
                    <AccruedTable
                      title={`Total Accrued`}
                      data={values?.accruedData?.total || {}}
                    />
                    <div style={{ marginRight: "20px" }}>
                      <p className="checkText">
                        <input
                          type="radio"
                          name="checkbox1"
                          className="checkSize"
                          checked={Boolean(values.supervisedFieldwork)}
                          onChange={handleRadioGroup1("supervisedFieldwork")}
                        />
                        Supervised Fieldwork
                      </p>
                      <p className="checkText">
                        <input
                          type="radio"
                          name="checkbox1"
                          className="checkSize"
                          checked={Boolean(values.practicum)}
                          onChange={handleRadioGroup1("practicum")}
                        />
                        Practicum
                      </p>
                      <p className="checkText">
                        <input
                          type="radio"
                          name="checkbox1"
                          className="checkSize"
                          checked={Boolean(values.intensivePracticum)}
                          onChange={handleRadioGroup1("intensivePracticum")}
                        />
                        Intensive Practicum
                      </p>
                    </div>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.superviseeCheck}
                      onChange={handleCheckChange("superviseeCheck")}
                    />
                    <span>Supervisee Check</span>
                  </label>

                  <div className="column mt20" style={{ margin: "0px 30px" }}>
                    <label style={{ lineHeight: "30px" }}>
                      Supervisee questions and feedback:
                    </label>
                    <textarea
                      className="textarea  "
                      placeholder="Type here..."
                      value={values.superviseeFeedback}
                      disabled
                    ></textarea>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.observationChecK}
                      onChange={handleCheckChange("observationChecK")}
                    />
                    <span>Observation Check</span>
                  </label>

                  <div
                    className="mt20 agendasec3"
                    style={{ margin: "20px 50px" }}
                  >
                    <p>{`Observation date:   ${values.observationDate}`}</p>
                    <br />
                    <label className="mt10">Skills demonstrated:</label>
                    <br />
                    <p>
                      {
                        skills.find((item) => item.id == Number(values.skills))
                          ?.name
                      }
                    </p>
                    <p className="mt30">Feedback:</p>
                    <textarea
                      className="textarea  "
                      placeholder="Type here..."
                      value={values.observationFeedback}
                      onChange={handleChange("observationFeedback")}
                      disabled
                    ></textarea>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.clinicalPracticeCheck}
                      onChange={handleChange("clinicalPracticeCheck")}
                    />
                    <span>Clinical Practice Check</span>
                  </label>

                  <div className="mt30" style={{ margin: "20px 50px" }}>
                    <p>{`Type:  ${values.type}`}</p>
                    <div className="check mt10">
                      <p>
                        <input
                          type="radio"
                          name="checkGroup2"
                          className="checkSize"
                          checked={values.completed}
                          onChange={handleRadioGroup2("completed")}
                        />
                        Completed
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="checkGroup3"
                          className="checkSize"
                          checked={values.inPerson}
                          onChange={handleRadioGroup3("inPerson")}
                        />
                        In person
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="checkGroup2"
                          className="checkSize"
                          checked={values.notCompleted}
                          onChange={handleRadioGroup2("notCompleted")}
                        />
                        Not completed
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="checkGroup3"
                          className="checkSize"
                          checked={values.online}
                          onChange={handleRadioGroup3("online")}
                        />
                        Online
                      </p>
                    </div>
                    <p className="mt30" style={{ lineHeight: "30px" }}>
                      Feedback:
                    </p>
                    <textarea
                      className="textarea  "
                      placeholder="Type here..."
                      value={values.clinicalPracticeFeedback}
                      onChange={handleChange("clinicalPracticeFeedback")}
                      disabled
                    ></textarea>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.taskListReviewCheck}
                      onChange={handleCheckChange("taskListReviewCheck")}
                    />
                    <span>Task List Review Check</span>
                  </label>

                  <div
                    className="mt30 agendaTable"
                    style={{ margin: "20px 20px" }}
                  >
                    <table>
                      <tr className="noBorder">
                        <th
                          rowSpan="2"
                          align="left"
                          className="agendaTableHeader"
                          style={{ textAlign: "left", paddingLeft: "16px" }}
                        >
                          Task
                        </th>
                        <th colSpan="2" className="agendaTableHeader">
                          Assessment
                        </th>
                        <th rowSpan="2" className="agendaTableHeader">
                          Feedback
                        </th>
                        <th rowSpan="2" className="agendaTableHeader">
                          Review
                        </th>
                      </tr>
                      <tr>
                        <th className="agendaTableText">Can Demonstrate</th>
                        <th className="agendaTableText"> Can Explain</th>
                      </tr>
                      {values.tasks?.length > 0 &&
                        values.tasks?.map((task, i) => (
                          <tr key={i}>
                            <td
                              style={{
                                width: "30%",
                                textAlign: "left",
                                paddingLeft: "16px",
                              }}
                            >
                              {task?.name}
                            </td>
                            <td style={{ width: "10%" }}>
                              <input
                                type="checkbox"
                                className=""
                                checked={task?.canDemonstrate}
                                readOnly
                              />
                            </td>
                            <td style={{ width: "10%" }}>
                              <input
                                type="checkbox"
                                className=""
                                checked={task?.canExplain}
                                readOnly
                              />
                            </td>
                            <td style={{ width: "20%" }}>{task?.feedback}</td>
                            <td style={{ width: "20%" }}>{task?.review}</td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function TestElement() {
  const printPDF = () => {
    // window.scrollTo(0, 0);
    const domElement = document.getElementById("roooooot");

    html2canvas(
      domElement

      //  {
      // onclone: (document) => {
      //   document.getElementById("print").style.visibility = "hidden";
      // },
      // { scrollY: -window.scrollY }
      // }
    ).then((canvas) => {
      console.log({ canvas });
      const pdf = new jsPDF();
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      let widthRatio = width / canvas.width;
      let heightRatio = height / canvas.height;

      let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      const imgData = canvas.toDataURL("image/png");
      console.log({ imgData });
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        canvas.width * ratio,
        canvas.height * ratio
      );
      pdf.save(`${new Date().toISOString()}.pdf`);
    });
  };

  return (
    <div id="rooooot">
      <h1>Generate PDF</h1>
      <p>Create a screenshot from the page, and put it in a PDF file.</p>
      <p style={{ color: "red" }}>
        *Open this page in new window and press the button.
      </p>
      <button id="print" onClick={printPDF}>
        PRINT
      </button>
    </div>
  );
}

const AccruedTable = ({ title, data }) => {
  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = data;

  return (
    <div>
      <table className="agenda-table">
        <tr className="barTableHeader">
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <td>Independent Hours</td>
          <td className="colSize1">{independent_hours || ""}</td>
        </tr>
        <tr>
          <td>Unrestricted Hours</td>
          <td className="colSize1">{unrestricted_hours || ""}</td>
        </tr>
        <tr>
          <td>% Unrestricted</td>
          <td className="colSize1">{percent_unrestricted?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td># Supervision Contacts</td>
          <td className="colSize1">{supervision_contacts?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td>% Supervision</td>
          <td className="colSize1">{""}</td>
        </tr>
      </table>
    </div>
  );
};

export default MonthlyDocument;
