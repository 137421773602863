import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setUserProfile, setError, setSupervisorData, setUpdateSuccess } from "../../reducers/ProfileReducer";

export const updateProfile = createAction("profile/updateProfile");

function* updateData({ payload }) {
  const { id, profile } = payload; 
  console.log('payload: ', payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.updateProfile, id, profile);
    if(res.data.id) {
      yield put(setUserProfile(res.data));
      yield put(setUpdateSuccess(true));
    } else {
      yield put(setUpdateSuccess(false));
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error));

  }
}

export function* updateProfileSaga() {
  yield takeLatest(updateProfile, updateData);
}

export const updateSupervisor = createAction("Supervisor/updateSupervisor");

function* updateSupervisorData({ payload }) {
  const { id, supervisor } = payload; 
  console.log('payload: ', payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.updateSupervisor, id, supervisor);
    if(res.data.id) {
      yield put(setSupervisorData(res.data));
      yield put(setUpdateSuccess(true));
    } else {
      yield put(setUpdateSuccess(false));
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error));

  }
}

export function* updateSupervisorSaga() {
  yield takeLatest(updateSupervisor, updateSupervisorData);
}
