import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import "./dashboard.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import pacModal from "../../../assets/pacModal.png";
import pavlov from "../../../assets/Pavlov.svg";
import {
  useChartData,
  useIsLoading,
  usetableData,
} from "../../../redux/reducers/DashboardReducer";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useDispatchEffect } from "../../../utils/hooks";
import { fetchChartData } from "../../../redux/sagas/dasboard/fetchChartSaga";
import DoughnutChart from "../../../components/DoughnutChart";
import { fetchTable } from "../../../redux/sagas/dasboard/fetchTableSaga";
import moment from "moment";
import { useBadgeValue } from "../../../redux/reducers/ProfileReducer";
import { findPercentage, findSum } from "../../../services/utiltities";

const options = {
  plugins: {
    subtitle: {
      display: true,
      text: "71% Positive",
      verticalAlign: "center",
      fontSize: 24,
      dockInsidePlotArea: true,
    },
  },
};

const Dashboard = () => {
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();
  const chartData = useChartData();
  const tableData = usetableData();
  const isLoading = useIsLoading();
  const badgeValue = useBadgeValue();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const unweightedTotal = {
    total_hours: findSum("total_hours", tableData),
    independent: findSum("independent", tableData),
    supervised: findSum("supervised", tableData),
    restricted: findSum("restricted", tableData),
    unrestricted: findSum("unrestricted", tableData),
  };

  const { total_hours, restricted_unrestricted, supervision_unsupervised } =
    chartData || {};

  const supervisedHrs = total_hours?.supervised || 0;
  const concentratedHrs = total_hours?.concenterated || 0;
  const restricted = restricted_unrestricted?.restricted || 0;
  const unrestricted = restricted_unrestricted?.unrestricted || 0;
  const supervised = supervision_unsupervised?.supervised || 0;
  const unsupervised = supervision_unsupervised?.unsupervised || 0;

  useDispatchEffect(fetchChartData, null, true);
  useDispatchEffect(fetchTable, null, true);

  return (
    <div className="section">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <div className="dashHead">
            <p className="headingMain">Dashboard </p>
            <button onClick={onOpenModal}>
              <img className="pavlov" src={pavlov} />
            </button>
          </div>

          <Modal open={open} onClose={onCloseModal} center>
            <div className="customModal">
              <img className="pacModal mt30" src={pacModal} />
              <p className="mt25">{badgeValue}% Completed</p>
              <p
                className="mt25"
                style={{ textAlign: "center", width: "400px" }}
              >
                Non venenatis varius aliquet nec adipiscing vestibulum felis in
                orci. A vestibulum egestas scelerisque semper fa!
              </p>
            </div>
          </Modal>

          {isLoading ? (
            <LoadingSpinner style={{ width: "100%" }} />
          ) : (
            <div className="columns mt30">
              <div className="column row-space-evenly">
                <div className="cards bottom-text-div">
                  <p className="card-text"> TOTAL HOURS COMPLETED </p>
                  <div className="doughnut-div-main mt10">
                    <div className="doughnut-div">
                      <DoughnutChart
                        label="Total Hours"
                        data={
                          concentratedHrs + supervisedHrs > 0
                            ? [supervisedHrs, concentratedHrs]
                            : [0, 1]
                        }
                        backgroundColor={
                          concentratedHrs + supervisedHrs > 0
                            ? ["#75D6D1", "#F4DEEA"]
                            : ["#ddd", "#ddd"]
                        }
                        height={100}
                        width={100}
                        options={options}
                      />
                    </div>
                  </div>
                  <div className="bottom">
                    <p className="card-text"> Supervised: {supervisedHrs}</p>
                    <p className=""> Concentrated: {concentratedHrs}</p>
                  </div>
                </div>
                <div className="cards bottom-text-div">
                  <p className="card-text">
                    {" "}
                    RESTRICTED VS UNRESTRICTED HOURS{" "}
                  </p>

                  <div className="doughnut-div-main mt10">
                    <div className="doughnut-div">
                      <DoughnutChart
                        label="Restricted/unrestricted"
                        data={[restricted, unrestricted]}
                        backgroundColor={["#75D6D1", "#ddd"]}
                        height={100}
                        width={100}
                      />
                      <div className="bottom">
                        <p className="card-text">Restricted</p>
                      </div>
                    </div>
                    <div className="doughnut-div">
                      <DoughnutChart
                        label="Restricted/unrestricted"
                        data={[unrestricted, restricted]}
                        backgroundColor={["#F9E18A", "#ddd"]}
                        height={100}
                        width={100}
                      />
                      <div className="bottom">
                        <p className="card-text">Unrestricted</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cards bottom-text-div">
                  <p className="card-text"> SUPERVISED VS INDEPENDENT HOURS </p>
                  <div className="doughnut-div-main mt10">
                    <div className="doughnut-div">
                      <DoughnutChart
                        label="Supervision/unsupervised"
                        data={[supervised, unsupervised]}
                        backgroundColor={["#75D6D1", "#ddd"]}
                        height={100}
                        width={100}
                      />
                      <div className="bottom">
                        <p className="card-text">Supervised</p>
                      </div>
                    </div>
                    <div className="doughnut-div">
                      <DoughnutChart
                        label="Supervision/unsupervised"
                        data={[unsupervised, supervised]}
                        backgroundColor={["#F9E18A", "#ddd"]}
                        height={100}
                        width={100}
                      />
                      <div className="bottom">
                        <p className="card-text">Independent</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className='bottom'>
                                    <p className='card-text'> 43.3% Supervised </p>
                                    <p className='card-text'>
                                    </p>
                                </div> */}
                </div>
              </div>
            </div>
          )}

          <p className="headingMain mt30">Monthly Details</p>
          <div className="mt30 agendaTable">
            <table>
              <tr>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Month
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Total Hours
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Independent
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Supervised
                </th>

                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Supervision %
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Restricted
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Unrestricted
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Unrestricted %
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Type
                </th>
              </tr>
              <tbody>
                {isLoading ? (
                  <LoadingSpinner style={{ width: "100%" }} />
                ) : (
                  tableData.map((item, key) => {
                    return (
                      <tr key={key} className="tablevalue">
                        <td style={{ textAlign: "center" }}>
                          {getDate(item.month)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["total_hours"]).toFixed(3)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["independent"]).toFixed(3)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["supervised"]).toFixed(3)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["supervised%"]).toFixed(1)}%
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["restricted"]).toFixed(3)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["unrestricted"]).toFixed(3)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {Number(item["unrestricted%"]).toFixed(1)}%
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.type?.length ? item.type.join(", ") : ""}
                        </td>
                      </tr>
                    );
                  })
                )}
                {!isLoading && tableData.length > 0 && (
                  <tr className="totalvalue">
                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                      Unweighted Total
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Number(unweightedTotal["total_hours"]).toFixed(3)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Number(unweightedTotal["independent"]).toFixed(3)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Number(unweightedTotal["supervised"]).toFixed(3)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {findPercentage(
                        unweightedTotal["supervised"],
                        unweightedTotal["total_hours"]
                      ).toFixed(1)}
                      %
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Number(unweightedTotal["restricted"]).toFixed(3)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Number(unweightedTotal["unrestricted"]).toFixed(3)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {findPercentage(
                        unweightedTotal["unrestricted"],
                        unweightedTotal["restricted"] +
                          unweightedTotal["unrestricted"]
                      ).toFixed(1)}
                      %
                    </td>
                    <td style={{ textAlign: "center" }}>{""}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const getDate = (month) => {
  const date = new Date();
  date.setMonth(month - 1);
  return moment(date).format("MMMM YYYY");
};
export default Dashboard;
