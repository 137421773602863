import Backdrop from "@mui/material/Backdrop";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOutAction } from "../redux/sagas/auth/AuthSagas";

export default function SubscriptionDialog({ isVisible, closeDialog }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onContinue = () => {
    closeDialog();
    navigate("/subscription");
  };
  const onBack = () => {
    dispatch(signOutAction());
    navigate("/");
    window.location.reload();
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isVisible}
      onClick={() => {}}
      style={{ backdropFilter: "blur(1px)" }}
    >
      <div className="subscription-dialog">
        <h1>Looks like your 7 day free trial has expired.</h1>
        <div className="buttons">
          <a
            className="button is-large is-rounded continue-button"
            onClick={onContinue}
          >
            Continue
          </a>
          <a
            className="button is-large is-rounded back-button"
            onClick={onBack}
          >
            Back
          </a>
        </div>
      </div>
    </Backdrop>
  );
}
